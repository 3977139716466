<template>
<v-col cols="12" sm="8" md="6" class="mx-auto">
  <v-card v-if="$store.state.auth.isLogin" class="mx-auto">

    <v-col cols="12">
      <h1>Bonjour {{ $store.state.auth.user.name }} !</h1>
    </v-col>
      
  </v-card>
  <v-card v-if="$store.state.auth.isAdmin" class="mx-auto">

    <v-col cols="12">
      <h3>Vous êtes administrateur du système</h3>
    </v-col>
      
  </v-card>
</v-col>
</template>

<script>

//import axios from 'axios'
//import router from '../router/router'

export default {
  name: 'auth',
  components: {},
  data: () => ({
    
  }),
  async mounted(){
      
  },
  methods: {
    
  }
}
</script>
